import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            I am <span className="purple">Kai Lee </span>
            from <span className="purple"> Singapore.</span>
            <br />Experienced Web3 developer specializing in dApp development on
            EVM-based chains. Proficient in smart contract development and
            DeFi protocols. Passionate about leveraging blockchain technology
            to create innovative, decentralized solutions.
            <br />
            <br />
            Apart from coding, some other activities that I love to do!
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Games
            </li>
            <li className="about-activity">
              <ImPointRight /> Sports
            </li>
            <li className="about-activity">
              <ImPointRight /> Travelling
            </li>
          </ul>

          <p style={{ color: "rgb(155 126 172)" }}>
            "If you cannot grok the overall structure of a program while taking a shower, you are not ready to code it."{" "}
          </p>
          <footer className="blockquote-footer">Richard Pattis</footer>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
