import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import youbuidl from "../../Assets/Projects/youbuidl.png";
import hoodygang from "../../Assets/Projects/hoodygang.png";
import darkclover from "../../Assets/Projects/darkclover.png";
import ethlizards from "../../Assets/Projects/ethlizards.png";
import fingernft from "../../Assets/Projects/fingernft.png";
import aircoin from "../../Assets/Projects/aircoin.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={youbuidl}
              isBlog={false}
              title="YouBuidl"
              description="YouBuidl is crowdfunding web3 tool created by GiveStation for developers, creators and teams."
              demoLink="https://youbuidl.dev/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={hoodygang}
              isBlog={false}
              title="HoodyGang"
              description="HoodyGang presents a revolutionary platform for customizing NFTs through interchangeable traits."
              demoLink="https://dapp.blockchain-buds.com"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={darkclover}
              isBlog={false}
              title="Dark Clover"
              description="Dark Clover is a new and unique NFT game set in an apocalyptic world. 
              Buy DSEED$ and plant them into your Dark Land to get daily rewards."
              demoLink="https://dark-clover-dapp.vercel.app/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ethlizards}
              isBlog={false}
              title="Ethlizards"
              description="An NFT collection of 5,050 Ethlizards just vibin' and chillin' in their Underground Lounge. Every Ethlizard is unique, composed using over 100 hand-drawn traits. Ethlizards is a GameFi Investment DAO, aiming to utilize its excellent connections within the industry to gain access to pre-seed and seed investments."
              demoLink="https://dashboard.ethlizards.io/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={fingernft}
              isBlog={false}
              title="FINGERNFT"
              description="Fingernft system is a decentralized NFT market developed based on Ethereum. Fingernft draws lessons from rarible smart contract to realize fee free offline signature and help users reduce fee consumption while trading NFT."
              demoLink="https://fingernft.fingerchar.com/"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={aircoin}
              isBlog={false}
              title="AIRCOINS"
              description="In this move to earn mobile game, you'll be able to explore a whole new way of gaming by getting outdoors and unlocking digital treasures in Augmented Reality! Web3 and AR Metaverse is here. Do you have what it takes to conquer the world of Aircoins AR?"
              demoLink="https://www.aircoins.io/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
